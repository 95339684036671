<template>
  <div class="cups-info-bar-container">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="ma-0">
      <v-col cols="3" md="4" lg="3" class="home-info-bar-element">
        <div class="home-info-bar-inner elevation-3">
          <img class="info-bar-background" alt="E-Bike WM"
               v-lazyimg="'https://ebikecup.com/images/cups/hohesalve/EBCUPHOHESALVE_Teaserbild_01_03.jpg'">
          <v-col cols="12" style="z-index: 1; position: relative;height: 100%">
            <v-row style="height: 120px">
              <v-col cols="8" md="7" lg="7" xl="8" />
              <v-col cols="4" md="5" lg="5" xl="4" class="align-self-center" style="text-align: left;color: white;">
                <strong><i>{{ $t('cup.hohesalve.infobar.new') }}:</i></strong> <br>
                {{ $t('cup.hohesalve.infobar.presentation') }}
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-col>
      <v-col cols="6" md="4" lg="6" class="home-info-bar-element">
        <div class="home-info-bar-inner elevation-3">
          <img class="info-bar-background" alt="E-Bike WM"
               v-lazyimg="'https://ebikecup.com/images/cups/hohesalve/EBCUPHOHESALVE_Teaserbild_01_02.jpg'">
          <div class="info-bar-title-container">
            {{ $t('cup.hohesalve.infobar.slogan') }}
          </div>
        </div>
      </v-col>
      <v-col cols="3" md="4" lg="3" class="home-info-bar-element">
        <div class="home-info-bar-inner elevation-3">
          <v-col cols="12" style="height: 100%">
            <v-row style="z-index: 1; position: relative;margin-top: -12px;">
              <v-col cols="5" class="pa-0" style="position: relative">
                <img class="info-bar-image" alt="E-Bike WM"
                     v-lazyimg="'https://ebikecup.com/images/infobar/EBCUP_Teaserbild_E-Bike_Cup_02.jpg'">
                <h1 style="color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-style: italic; font-weight: 500;">
                  LICENSE
                </h1>
              </v-col>
              <v-col cols="7 pa-2" style="text-align: left;">
                <div>
                  <div style="height: 75px">
                    <div>
                      <strong>{{ $t('home.infoBar.license') }}</strong>
                    </div>
                    <div style="line-height: 1.2">
                      {{ $t('home.infoBar.licenseDescription') }}
                    </div>
                  </div>
                  <v-btn outlined color="primary" @click.stop="goToAnchor('#license')">
                    {{ $t("home.infoBar.news.discoverNow") }}
                    <v-icon>{{icons.right}}</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <div class="outer-slider-wrapper" style="padding-left: 5px;" v-else>
      <div class="scrolling-wrapper-flexbox">
        <v-col class="home-info-bar-element info-bar-slider-item">
          <div class="home-info-bar-inner elevation-3">
            <img class="info-bar-background" alt="E-Bike WM"
                 v-lazyimg="'https://ebikecup.com/images/cups/hohesalve/EBCUPHOHESALVE_Teaserbild_01_03.jpg'">
            <v-col cols="12" style="position: relative;">
              <v-row style="height: 120px">
                <v-col cols="7" md="7" lg="7" xl="8" />
                <v-col cols="5" md="5" lg="5" xl="4" class="align-self-center" style="text-align: left;color: white;">
                  <strong><i>{{ $t('cup.hohesalve.infobar.new') }}:</i></strong> <br>
                  {{ $t('cup.hohesalve.infobar.presentation') }}
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
        <v-col class="home-info-bar-element info-bar-slider-item">
          <div class="home-info-bar-inner elevation-3">
            <img class="info-bar-background" alt="E-Bike WM"
                 v-lazyimg="'https://ebikecup.com/images/cups/hohesalve/EBCUPHOHESALVE_Teaserbild_01_02.jpg'">
            <div class="info-bar-title-container">
              {{ $t('cup.hohesalve.infobar.slogan') }}
            </div>
          </div>
        </v-col>
        <v-col class="home-info-bar-element info-bar-slider-item">
          <div class="home-info-bar-inner elevation-3">
            <v-col cols="5" class="pa-0" style="position: relative">
              <img class="info-bar-image" alt="E-Bike WM"
                   v-lazyimg="'https://ebikecup.com/images/infobar/EBCUP_Teaserbild_E-Bike_Cup_02.jpg'">
              <h1 style="color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-style: italic; font-weight: 500;">
                LICENSE
              </h1>
            </v-col>
            <v-col cols="7" class="pa-0" style="text-align: left;">
              <div style="padding: 14px;">
                <div>
                  <strong>{{ $t('home.infoBar.license') }}</strong>
                </div>
                <div style="line-height: 1.2">
                  {{ $t('home.infoBar.licenseDescription') }}
                </div>
                <v-btn outlined color="primary" @click.stop="goToAnchor('#license')">
                  {{ $t("home.infoBar.news.discoverNow") }}
                  <v-icon>{{icons.right}}</v-icon>
                </v-btn>
              </div>
            </v-col>
          </div>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
  import {mdiChevronRight} from '@mdi/js'
  export default {
    name: 'info-bar',
    data() {
      return {
        icons: {
          right: mdiChevronRight,
        },
      };
    },
    methods: {
      goToAnchor(selector) {
        if (selector !== undefined && selector !== null) {
          this.globalEvents.$emit('goToElementCups', selector);
        }
      },
      goToLanding() {
        this.$router.push({ name: 'home' });
      }
    }
  };
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  @import "../../../assets/scss/layout";
  .cups-info-bar-container {
    font-family: $default-font;
    position: relative;
    background-color: $basic-gray;
    @include MQ(M) {
      padding: 20px;
    }
    @include MQ(L) {
      padding: 20px;
    }
    @include MQ(XL) {
      padding: 20px;
    }
    .home-info-bar-element {
      padding: 10px;
      .home-info-bar-inner {
        height: 120px;
        position: relative;
        .info-bar-background {
          width: 100%;
          height: 120px;
          object-position: center;
          object-fit: cover;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 0;
          cursor: pointer;
        }

        .info-bar-title-container {
          position: absolute;
          cursor: pointer;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 30px;
          color: white;
          font-family: $default-font;
          font-weight: 400;
          width: 100%;
          @include MQ(M) {
            font-size: 22px;
          }
          @include MQ(S) {
            font-size: 22px;
          }
          @include MQ(XS) {
            font-size: 20px;
          }
          img {
            cursor: pointer;
            height: 70px;
            margin-left: 15px;
            margin-bottom: -20px;
            @include MQ(M) {
              height: 60px;
              margin-left: 20px;
              margin-bottom: -25px;
            }
            @include MQ(S) {
              height: 60px;
              margin-left: 20px;
              margin-bottom: -20px;
            }
            @include MQ(XS) {
              height: 60px;
              margin-left: 20px;
              margin-bottom: -20px;
            }
          }
        }

        .info-bar-image {
          width: 100%;
          height: 120px;
          object-position: center;
          object-fit: cover;
          left: 0;
          top: 0;
        }
        .v-btn {
          border-radius: 15px;
          border-width: 2px;
          padding: 0 5px;
          height: 30px;
          text-transform: none;
          .v-btn__content {
            padding: 0;
            height: 25px;
          }
        }
      }
    }

    /********************** Infinite Slider (Mobile) **********************/
    .outer-slider-wrapper {
      height: 100%;
      width: 100%;
      overflow-y: hidden;

      .scrolling-wrapper-flexbox {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;

        .info-bar-slider-item {
          min-width: 350px;
          margin-right: 10px;

          &.guiness-item {
            @include MQ(XS) {
              min-width: 300px;
            }
            @include MQ(S) {
              min-width: 330px;
            }
          }
        }

        ::-webkit-scrollbar {
          display: none;
          width: 0;  /* remove scrollbar space */
          background: transparent;  /* optional: just make scrollbar invisible */
        }
        &::-webkit-scrollbar {
          display: none;
          width: 0;  /* remove scrollbar space */
          background: transparent;  /* optional: just make scrollbar invisible */
        }
      }
    }
  }
</style>
